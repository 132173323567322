<template>
    <section id="insulation-training-section">
        <div class="wrap insulation-training">
            <div class="insulation-training-content">
                <div class="insulation-training-info">
                    <p>Training sessions and site tests are conducted under the supervision of FROSIO Level III certified instructors, 
                        with all training tailored to meet NORSOK standards. </p>
                    <p>Equipped to test and train insulation fitters as well as specialists in insulation box measurement and surveying.</p>
                </div>
                <div class="insulation-training-img">
                    <img class="side-pic" :src="images.InsulationTriningIMG" crossorigin=""/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'InsulationTraining',

    data(){
            return{
                images: {
                    InsulationTriningIMG: require('@/assets/Training/Insulation-Training-01.png')
                },
            }
    
        },

    created () {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        counters(){
        const figures = document.querySelectorAll('.figure');
        

        figures.forEach(figure=> {
            const updateCount = () => {
                const target = figure.getAttribute('data-target');

                console.log(target);
            }
            updateCount();
        })

        }

    }
}
</script>

<style src='./InsulationTraining.css'>

</style>
